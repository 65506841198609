import { ethers } from 'ethers';
import { toast } from 'react-toastify'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import useSWR from 'swr'

import OrderBook from './abis/OrderBook.json'
import Router from './abis/Router.json'
import { getContract } from './Addresses'
import {
  CHAIN_ID,
  NATIVE_TOKEN_ADDRESS,
  SWAP_ORDER_EXECUTION_GAS_FEE,
  INCREASE_ORDER_EXECUTION_GAS_FEE,
  DECREASE_ORDER_EXECUTION_GAS_FEE,
  getExplorerUrl,
  getServerBaseUrl,
  getGasLimit,
  replaceNativeTokenAddress
} from './Helpers'

const orderBookAddress = getContract(CHAIN_ID, "OrderBook")
const routerAddress = getContract(CHAIN_ID, "Router")

const { AddressZero } = ethers.constants

const BTC_USD_FEED_ID = "0xae74faa92cb67a95ebcab07358bc222e33a34da7";
const BNB_USD_FEED_ID = "0xc45ebd0f901ba6b2b8c7e70b717778f055ef5e6d";
const ETH_USD_FEED_ID = "0x37bc7498f4ff12c19678ee8fe19d713b87f6a9e6";

const FEED_ID_MAP = {
  "BTC_USD": BTC_USD_FEED_ID,
  "ETH_USD": ETH_USD_FEED_ID,
  "BNB_USD": BNB_USD_FEED_ID
};

const CHAINLINK_GRAPH_API_URL = "https://api.thegraph.com/subgraphs/name/deividask/chainlink";
const chainlinkClient = new ApolloClient({
  uri: CHAINLINK_GRAPH_API_URL,
  cache: new InMemoryCache()
});

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg)
  }
}

function getChartPrices(marketName) {
  const feedId = FEED_ID_MAP[marketName];
  const PER_CHUNK = 1000;
  const CHUNKS_TOTAL = 6;

  const requests = [];
  for (let i = 0; i < CHUNKS_TOTAL; i++) {
    const query = gql(`{
      rounds(
        first: ${PER_CHUNK},
        skip: ${i * PER_CHUNK},
        orderBy: unixTimestamp,
        orderDirection: desc,
        where: {feed: "${feedId}"}
      ) {
        unixTimestamp,
        value
      }
    }`)
    requests.push(chainlinkClient.query({query}))
  }

  return Promise.all(requests).then(chunks => {
    const prices = [];
    const uniqTs = new Set();
    chunks.forEach(chunk => {
      chunk.data.rounds.forEach(item => {
        if (uniqTs.has(item.unixTimestamp)) {
          return;
        }

        uniqTs.add(item.unixTimestamp)
        prices.push([
            item.unixTimestamp,
            Number(item.value) / 1e8
        ]);
      })
    });

    return prices.sort(([timeA], [timeB]) => timeA - timeB);
  }).catch(err => {
    console.error(err);
  })
}

export function useTrades(account) {
  const url = account ? `${getServerBaseUrl()}/actions?account=${account}` : false
  const { data: trades, mutate: updateTrades } = useSWR(url, {
    dedupingInterval: 30000,
    fetcher: (...args) => fetch(...args).then(res => res.json())
  })

  return { trades, updateTrades }
}

export function useChartPrices(marketName, sample) {
  const { data = [], mutate: updatePrices } = useSWR(['getChartPrices', marketName], {
    fetcher: () => getChartPrices(marketName)
  })

  let prices;
  if (sample && data && data.length) {
    const SAMPLE_INTERVAL = 60 * 60 * 1;
    prices = [];
    let lastAddedTimestamp = data[0][0];
    data.forEach((item, i) => {
      if (item[0] - lastAddedTimestamp < SAMPLE_INTERVAL && i !== data.length - 1) {
        return;
      }
      prices.push(item);
      lastAddedTimestamp = item[0];
    })
  } else {
    prices = data;
  }

  return [prices, updatePrices];
}

export function approvePlugin(pluginAddress, { setIsApproving, library, onApproveSubmitted, pendingTxns, setPendingTxns }) {
  setIsApproving(true);

  const contract = new ethers.Contract(routerAddress, Router.abi, library.getSigner())
  return callContract(contract, 'approvePlugin', [pluginAddress], {
    sentMsg: 'Enable trigger orders sent',
    successMsg: 'Trigger orders enabled',
    failMsg: 'Enable trigger orders failed',
    pendingTxns,
    setPendingTxns
  })
  .then(() => {
    if (onApproveSubmitted) { onApproveSubmitted() }
  })
  .finally(() => {
    setIsApproving(false)
  })
}

export function createSwapOrder(
  library,
  path,
  amountIn,
  minOut,
  triggerRatio,
  opts = {}
) {
  const executionFee = SWAP_ORDER_EXECUTION_GAS_FEE
  const triggerAboveThreshold = false
  let shouldWrap = false
  let shouldUnwrap = false
  opts.value = executionFee

  if (path[0] === AddressZero) {
    shouldWrap = true
    opts.value = opts.value.add(amountIn)
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true
  }
  path = replaceNativeTokenAddress(path)

  const params = [
    path,
    amountIn,
    minOut,
    triggerRatio,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
    shouldUnwrap
  ]

  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, 'createSwapOrder', params, opts)
}

export function createIncreaseOrder(
  library,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses")
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0")
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0")
  
  const triggerAboveThreshold = !isLong
  const executionFee = INCREASE_ORDER_EXECUTION_GAS_FEE
  const shouldWrap = path[0] === NATIVE_TOKEN_ADDRESS

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap
  ]

  if (!opts.value) {
    opts.value = path[0] === NATIVE_TOKEN_ADDRESS ? amountIn.add(executionFee) : executionFee
  }

  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, 'createIncreaseOrder', params, opts)
}

export function createDecreaseOrder(
  library,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses")
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0")
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0")

  const executionFee = DECREASE_ORDER_EXECUTION_GAS_FEE

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold
  ];
  opts.value = executionFee
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, 'createDecreaseOrder', params, opts)
}

export function cancelSwapOrder(library, index, opts) {
  const params = [index];
  const method = 'cancelSwapOrder';
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, method, params, opts);
}

export function cancelDecreaseOrder(library, index, opts) {
  const params = [index];
  const method = 'cancelDecreaseOrder';
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, method, params, opts);
}

export function cancelIncreaseOrder(library, index, opts) {
  const params = [index];
  const method = 'cancelIncreaseOrder';
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, method, params, opts);
}

export function updateDecreaseOrder(library, index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold, opts) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold]
  const method = 'updateDecreaseOrder';
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, method, params, opts);
}

export function updateIncreaseOrder(library, index, sizeDelta, triggerPrice, triggerAboveThreshold, opts) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = 'updateIncreaseOrder';
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, method, params, opts);
}

export function updateSwapOrder(library, index, minOut, triggerRatio, triggerAboveThreshold, opts) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold];
  const method = 'updateSwapOrder';
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())

  return callContract(contract, method, params, opts);
}

export function _executeOrder(library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner())
  return callContract(contract, method, params, opts);
}

export function executeSwapOrder(library, account, index, feeReceiver, opts) {
  _executeOrder(library, 'executeSwapOrder', account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(library, account, index, feeReceiver, opts) {
  _executeOrder(library, 'executeIncreaseOrder', account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(library, account, index, feeReceiver, opts) {
  _executeOrder(library, 'executeDecreaseOrder', account, index, feeReceiver, opts);
}

async function callContract(contract, method, params, opts = {}) {
  if (!opts.gasLimit) {
    opts.gasLimit = await getGasLimit(contract, method, params, opts.value)
  }

  return contract[method](...params, { gasLimit: opts.gasLimit, value: opts.value })
    .then(async (res) => {
      const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
      const sentMsg = opts.sentMsg || "Transaction sent."
      toast.success(
        <div>
          {sentMsg}. <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
          <br/>
        </div>
      );
      if (opts.pendingTxns && opts.setPendingTxns) {
        const pendingTxn = {
          hash: res.hash,
          message: opts.successMsg || "Transaction sent"
        }
        opts.setPendingTxns([...opts.pendingTxns, pendingTxn])
      }
      if (opts.onSent) { opts.onSent() }
      return res;
    })
    .catch((e) => {
      console.error(e)
      toast.error(opts.failMsg || "Transaction failed");
  });
}
