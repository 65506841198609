import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"

import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom'

import Home from './Home'
import Presale from './Presale'
import Dashboard from './Dashboard'
import Stake from './Stake'
import Exchange from './Exchange'
import Actions from './Actions'
import Migration from './Migration'
import Debug from './Debug'

import cx from "classnames";
import { cssTransition } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { RiMenuLine } from 'react-icons/ri'
import { FaTimes } from 'react-icons/fa'

import './Font.css'
import './Shared.css'
import './App.css';

if ('ethereum' in window) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  return library
}

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 300
})

function AppHeaderLinks() {
  return (
    <div className="App-header-links">
      <div className="App-header-link-container">
        <NavLink activeClassName="active" to="/trade">TRADE</NavLink>
      </div>
      <div className="App-header-link-container">
        <NavLink activeClassName="active" to="/dashboard">DASHBOARD</NavLink>
      </div>
      <div className="App-header-link-container">
        <NavLink activeClassName="active" to="/earn">EARN</NavLink>
      </div>
      <div className="App-header-link-container">
        <NavLink activeClassName="active" to="/migrate">MIGRATE</NavLink>
      </div>
      <div className="App-header-link-container">
        <a href="https://gov.gambit.financial/" target="_blank" rel="noopener noreferrer">
          GOV
        </a>
      </div>
      <div className="App-header-link-container">
        <a href="https://gambit.gitbook.io/gambit/" target="_blank" rel="noopener noreferrer">
          ABOUT
        </a>
      </div>
    </div>
  )
}

function App() {
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined)
  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  }
  const slideVariants = {
    hidden: { y: "-100%" },
    visible: { y: 0 }
  }

  return (
    <Router>
      <div className="App">
        <div className="App-background"></div>
        <div className="App-highlight"></div>
        <div className="App-content">
          {isDrawerVisible &&
            <AnimatePresence>
              {isDrawerVisible &&
                <motion.div className="App-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                  >
                </motion.div>}
            </AnimatePresence>
          }
          <header>
            <div className="App-header large">
              <div className="App-header-link-container">
                <NavLink exact activeClassName="active" className="App-header-link-main" to="/">GAMBIT</NavLink>
              </div>
              <div className="App-header-links-container">
                {AppHeaderLinks()}
              </div>
            </div>
            <div className={cx("App-header", "small", { active: isDrawerVisible })} onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
              <div className={cx("App-header-link-container", "App-header-top", { active: isDrawerVisible })}>
                <div className="App-header-link-main">GAMBIT</div>
                <div>
                  {!isDrawerVisible && <RiMenuLine className="App-header-menu-icon" />}
                  {isDrawerVisible && <FaTimes className="App-header-menu-icon" />}
                </div>
              </div>
              <AnimatePresence>
                {isDrawerVisible &&
                  <motion.div
                    className="App-header-links-container App-header-drawer"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={slideVariants}
                    transition={{ duration: 0.2 }}
                    >
                  {AppHeaderLinks()}
                </motion.div>}
              </AnimatePresence>
            </div>
          </header>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Switch>
              <Route exact path="/">
                <Exchange />
              </Route>
              <Route exact path="/trade">
                <Exchange />
              </Route>
              <Route exact path="/presale">
                <Presale />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/earn">
                <Stake />
              </Route>
              <Route exact path="/about">
                <Home />
              </Route>
              <Route exact path="/debug">
                <Debug />
              </Route>
              <Route exact path="/actions/:account">
                <Actions />
              </Route>
              <Route exact path="/migrate">
                <Migration />
              </Route>
            </Switch>
          </Web3ReactProvider>
        </div>
      </div>
      <ToastContainer
        limit={2}
        transition={Zoom}
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
      />
    </Router>
  )
}

export default App;
