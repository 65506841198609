import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import { useWeb3React } from '@web3-react/core'
import cx from "classnames";
import useSWR from 'swr'
import { ethers } from 'ethers'

import {
  BASIS_POINTS_DIVISOR,
  USD_DECIMALS,
  CHAIN_ID,
  TESTNET,
  getTokenInfo,
  SWAP,
  LONG,
  SHORT,
  USDG_ADDRESS,
  getConnectWalletHandler,
  useEagerConnect,
  useInactiveListener,
  fetcher,
  formatAmount,
  expandDecimals,
  usePrevious,
  getExplorerUrl,
  getPositionKey,
  getUsd,
  getLiquidationPrice,
  getLeverage,
  useLocalStorageSerializeKey,
  getDeltaStr,
  useOrders
} from './Helpers'

import { getContract } from './Addresses'
import { getTokens, getWhitelistedTokens, getTokenBySymbol } from './data/Tokens'

import Reader from './abis/Reader.json'
import Vault from './abis/Vault.json'

import SwapBox from './components/Exchange/SwapBox'
import ExchangeChart from './components/Exchange/ExchangeChart'
import ExchangeTVChart from './components/Exchange/ExchangeTVChart'
import PositionSeller from './components/Exchange/PositionSeller'
import OrdersList from './components/Exchange/OrdersList'
import TradeHistory from './components/Exchange/TradeHistory'
import PositionEditor from './components/Exchange/PositionEditor'
import ExchangeWalletTokens from './components/Exchange/ExchangeWalletTokens'
import Tab from './components/Tab/Tab'
import Footer from "./Footer"

import './Exchange.css';

const NATIVE_TOKEN_ADDRESS = getContract(CHAIN_ID, "NATIVE_TOKEN")

const { AddressZero } = ethers.constants

const getTokenAddress = (token) => {
  if (token.address === AddressZero) {
    return NATIVE_TOKEN_ADDRESS
  }
  return token.address
}

export function getPositions(positionQuery, positionData, infoTokens, includeDelta) {
  const propsLength = 8
  const positions = []
  const positionsMap = {}
  if (!positionData) {
    return { positions, positionsMap }
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true)
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true)
    const key = getPositionKey(collateralTokens[i], indexTokens[i], isLong[i])

    const position = {
      key,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      hasProfit: positionData[i * propsLength + 6].eq(1),
      delta: positionData[i * propsLength + 7],
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice
    }

    position.pendingDelta = position.delta
    if (position.collateral.gt(0)) {
      if (position.delta.eq(0) && position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice) ? position.averagePrice.sub(position.markPrice) : position.markPrice.sub(position.averagePrice)
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice)
      }
      position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral)

      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta,
        deltaPercentage: position.deltaPercentage,
        hasProfit: position.hasProfit
      })

      position.deltaStr = deltaStr
      position.deltaPercentageStr = deltaPercentageStr
    }

    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta
    })

    positionsMap[key] = position

    if (position.size.gt(0)) {
      positions.push(position)
    }
  }

  return { positions, positionsMap }
}

function getPositionQuery(tokens) {
  const collateralTokens = []
  const indexTokens = []
  const isLong = []

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
    if (token.isStable) { continue }
    if (token.isWrapped) { continue }
    collateralTokens.push(getTokenAddress(token))
    indexTokens.push(getTokenAddress(token))
    isLong.push(true)
  }

  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i]
    if (!stableToken.isStable) { continue }

    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j]
      if (token.isStable) { continue }
      if (token.isWrapped) { continue }
      collateralTokens.push(stableToken.address)
      indexTokens.push(getTokenAddress(token))
      isLong.push(false)
    }
  }

  return { collateralTokens, indexTokens, isLong }
}

function getInfoTokens(tokens, tokenBalances, whitelistedTokens, vaultTokenInfo, fundingRateInfo) {
  const vaultPropsLength = 9
  const fundingRatePropsLength = 2
  const infoTokens = {}

  for (let i = 0; i < tokens.length; i++) {
    const token = JSON.parse(JSON.stringify(tokens[i]))
    if (tokenBalances) {
      token.balance = tokenBalances[i]
    }
    if (token.address === USDG_ADDRESS) {
      token.minPrice = expandDecimals(1, USD_DECIMALS)
      token.maxPrice = expandDecimals(1, USD_DECIMALS)
    }
    infoTokens[token.address] = token
  }

  for (let i = 0; i < whitelistedTokens.length; i++) {
    const token = JSON.parse(JSON.stringify(whitelistedTokens[i]))
    if (vaultTokenInfo) {
      token.poolAmount = vaultTokenInfo[i * vaultPropsLength]
      token.reservedAmount = vaultTokenInfo[i * vaultPropsLength + 1]
      token.availableAmount = token.poolAmount.sub(token.reservedAmount)
      token.usdgAmount = vaultTokenInfo[i * vaultPropsLength + 2]
      token.redemptionAmount = vaultTokenInfo[i * vaultPropsLength + 3]
      token.minPrice = vaultTokenInfo[i * vaultPropsLength + 4]
      token.maxPrice = vaultTokenInfo[i * vaultPropsLength + 5]
      token.guaranteedUsd = vaultTokenInfo[i * vaultPropsLength + 6]
    }

    if (fundingRateInfo) {
      token.fundingRate = fundingRateInfo[i * fundingRatePropsLength];
      token.cumulativeFundingRate = fundingRateInfo[i * fundingRatePropsLength + 1];
    }

    if (infoTokens[token.address]) {
      token.balance = infoTokens[token.address].balance
    }

    infoTokens[token.address] = token
  }

  return infoTokens
}

function PositionsList(props) {
  const {
    positions,
    positionsMap,
    infoTokens,
    active,
    account,
    library,
    pendingTxns,
    setPendingTxns,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    orders
  } = props
  const [positionToEditKey, setPositionToEditKey] = useState(undefined)
  const [positionToSellKey, setPositionToSellKey] = useState(undefined)
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(undefined)
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(undefined)
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined)

  const editPosition = (position) => {
    setCollateralTokenAddress(position.collateralToken.address)
    setPositionToEditKey(position.key)
    setIsPositionEditorVisible(true)
  }

  const sellPosition = (position) => {
    setPositionToSellKey(position.key)
    setIsPositionSellerVisible(true)
  }

  return (
    <div>
      <PositionEditor
        positionsMap={positionsMap}
        positionKey={positionToEditKey}
        isVisible={isPositionEditorVisible}
        setIsVisible={setIsPositionEditorVisible}
        infoTokens={infoTokens}
        active={active}
        account={account}
        library={library}
        collateralTokenAddress={collateralTokenAddress}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        getLiquidationPrice={getLiquidationPrice}
        getUsd={getUsd}
        getLeverage={getLeverage}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
      />
      {isPositionSellerVisible &&
        <PositionSeller
          positionsMap={positionsMap}
          positionKey={positionToSellKey}
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          infoTokens={infoTokens}
          active={active}
          account={account}
          orders={orders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
        />
      }
      {(positions) && <table className="Exchange-positions small border">
        <tbody>
          <tr className="Exchange-positions-header">
            <th>
              <div>Position</div>
              <div className="muted">Side</div>
            </th>
            <th>
              <div>Size</div>
              <div className="muted">PnL</div>
            </th>
            <th>
              <div>Entry Price</div>
              <div className="muted">Leverage</div>
            </th>
            <th>
              <div>Mark Price</div>
              <div className="muted">Liq. Price</div>
            </th>
            <th></th>
          </tr>
          {positions.length === 0 && (
            <tr>
              <td colSpan="4">
                No open positions
              </td>
            </tr>
          )}
          {positions.map(position => {
            const liquidationPrice = getLiquidationPrice(position)
            return (<tr key={position.key}>
              <td>
                <div className="Exchange-positions-title">{position.indexToken.symbol}</div>
                <div className={cx("Exchange-positions-side", { positive: position.isLong, negative: !position.isLong })}>
                  {position.isLong ? "Long" : "Short" }
                </div>
              </td>
              <td>
                <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                <div className={cx({ positive: position.hasProfit && position.pendingDelta.gt(0), negative: !position.hasProfit && position.pendingDelta.gt(0) })}>
                  {position.deltaStr} ({position.deltaPercentageStr})
                </div>
              </td>
              <td>
                <div>${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}</div>
                <div className="muted">{formatAmount(position.leverage, 4, 2, true)}x</div>
              </td>
              <td>
                <div>${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}</div>
                <div className="muted">${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}</div>
              </td>
              <td>
                <div>
                  <button className="Exchange-positions-action" onClick={() => editPosition(position)}>
                    Edit
                  </button>
                </div>
                <div>
                  <button  className="Exchange-positions-action" onClick={() => sellPosition(position)}>
                    Close
                  </button>
                </div>
              </td>
            </tr>)
          })}
        </tbody>
      </table>}
      <table className="Exchange-positions large">
        <tbody>
          <tr className="Exchange-positions-header">
            <th>Position</th>
            <th>Side</th>
            <th>Size</th>
            <th>Collateral</th>
            <th className="Exchange-positions-extra-info">Entry Price</th>
            <th className="Exchange-positions-extra-info">Mark Price</th>
            <th className="Exchange-positions-extra-info">Liq. Price</th>
            <th>PnL</th>
            <th></th>
            <th></th>
          </tr>
        {positions.length === 0 &&
          <tr>
            <td>No open positions</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="Exchange-positions-extra-info"></td>
            <td className="Exchange-positions-extra-info"></td>
            <td className="Exchange-positions-extra-info"></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        }
        {positions.map(position => {
          const liquidationPrice = getLiquidationPrice(position)
          return (
            <tr key={position.key}>
              <td>
                <div className="Exchange-positions-title">{position.indexToken.symbol}</div>
                <div className="Exchange-positions-leverage-container">
                  <div className="Exchange-positions-leverage">
                      {formatAmount(position.leverage, 4, 2, true)}x
                  </div>
                </div>
              </td>
              <td className={cx({ positive: position.isLong, negative: !position.isLong })}>
                {position.isLong ? "Long" : "Short" }
              </td>
              <td>
                ${formatAmount(position.size, USD_DECIMALS, 2, true)}
              </td>
              <td>
                ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
              </td>
              <td className="Exchange-positions-extra-info">${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}</td>
              <td className="Exchange-positions-extra-info">${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}</td>
              <td className="Exchange-positions-extra-info">${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}</td>
              <td className={cx({ positive: position.hasProfit && position.pendingDelta.gt(0), negative: !position.hasProfit && position.pendingDelta.gt(0) })}>
                  {position.deltaStr} ({position.deltaPercentageStr})
              </td>
              <td>
                <button className="Exchange-positions-action" onClick={() => editPosition(position)}>
                  Edit
                </button>
              </td>
              <td>
                <button  className="Exchange-positions-action" onClick={() => sellPosition(position)}>
                  Close
                </button>
              </td>
            </tr>
          )
        })
        }
        </tbody>
      </table>
    </div>
  )
}

export default function Exchange() {
  const tokens = getTokens(CHAIN_ID)
  const whitelistedTokens = getWhitelistedTokens(CHAIN_ID)
  const positionQuery = getPositionQuery(whitelistedTokens)
  const [pendingTxns, setPendingTxns] = useState([])

  const CHART_TV = 'TradingView'
  const [chartType, ] = useLocalStorageSerializeKey([CHAIN_ID, 'chartType'], CHART_TV)
  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [CHAIN_ID, "Exchange-pnl-in-leverage"],
    false
  );

  const [tokenSelection, setTokenSelection] = useLocalStorageSerializeKey([CHAIN_ID, "Exchange-token-selection"], {
    [SWAP]: {
      from: getTokenBySymbol(CHAIN_ID, "BNB").address,
      to: getTokenBySymbol(CHAIN_ID, "USDG").address,
    },
    [LONG]: {
      from: getTokenBySymbol(CHAIN_ID, "BNB").address,
      to: getTokenBySymbol(CHAIN_ID, "BNB").address,
    },
    [SHORT]: {
      from: getTokenBySymbol(CHAIN_ID, "BUSD").address,
      to: getTokenBySymbol(CHAIN_ID, "BTC").address,
    }
  })

  const [swapOption, setSwapOption] = useLocalStorageSerializeKey([CHAIN_ID, 'Swap-option'], SWAP)

  const [fromTokenAddress, setFromTokenAddress] = useState(tokenSelection[swapOption].from)
  const [toTokenAddress, setToTokenAddress] = useState(tokenSelection[swapOption].to)

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const { connector, activate, active, account, library, chainId } = useWeb3React()
  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) { setActivatingConnector(undefined) }
  }, [activatingConnector, connector])
  const triedEager = useEagerConnect()
  useInactiveListener(!triedEager || !!activatingConnector)
  const connectWallet = getConnectWalletHandler(activate)

  const vaultAddress = getContract(CHAIN_ID, "Vault")
  const readerAddress = getContract(CHAIN_ID, "Reader")

  const prevAccount = usePrevious(account)
  useEffect(() => {
    if (prevAccount !== account) {
      setPendingTxns([])
    }
  }, [prevAccount, account])

  const whitelistedTokenAddresses = whitelistedTokens.map(token => token.address)
  const { data: vaultTokenInfo, mutate: updateVaultTokenInfo } = useSWR([active, readerAddress, "getVaultTokenInfo"], {
    fetcher: fetcher(library, Reader, [vaultAddress, NATIVE_TOKEN_ADDRESS, expandDecimals(1, 18), whitelistedTokenAddresses]),
  })

  const tokenAddresses = tokens.map(token => token.address)
  const { data: tokenBalances, mutate: updateTokenBalances } = useSWR([active, readerAddress, "getTokenBalances", account], {
    fetcher: fetcher(library, Reader, [tokenAddresses]),
  })

  const { data: positionData, mutate: updatePositionData } = useSWR([active, readerAddress, "getPositions", vaultAddress, account], {
    fetcher: fetcher(library, Reader, [positionQuery.collateralTokens, positionQuery.indexTokens, positionQuery.isLong]),
  })
  const { data: fundingRateInfo, mutate: updateFundingRateInfo } = useSWR([active, readerAddress, "getFundingRates"], {
    fetcher: fetcher(library, Reader, [vaultAddress, NATIVE_TOKEN_ADDRESS, whitelistedTokenAddresses]),
  })
  const { data: maxUsdg, mutate: updateMaxUsdg } = useSWR([active, vaultAddress, "getMaxUsdgAmount"], {
    fetcher: fetcher(library, Vault),
  })

  let reducedMaxUsdg
  if (maxUsdg) {
    reducedMaxUsdg = maxUsdg // maxUsdg.mul(99).div(100)
  }

  useEffect(() => {
    const checkPendingTxns = async () => {
      const updatedPendingTxns = []
      for (let i = 0; i < pendingTxns.length; i++) {
        const pendingTxn = pendingTxns[i]
        const receipt = await library.getTransactionReceipt(pendingTxn.hash)
        if (receipt) {
          if (receipt.status === 0) {
            const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + pendingTxn.hash
            toast.error(
              <div>
              Txn failed. <a href={txUrl} target="_blank" rel="noopener noreferrer">View</a>
              <br/>
              </div>
            )
          }
          if (receipt.status === 1 && pendingTxn.message) {
            const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + pendingTxn.hash
            toast.success(
              <div>
              {pendingTxn.message}. <a href={txUrl} target="_blank" rel="noopener noreferrer">View</a>
              <br/>
              </div>
            )
          }
          continue
        }
        updatedPendingTxns.push(pendingTxn)
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns)
      }
    }

    const interval = setInterval(() => {
      checkPendingTxns()
    }, 2 * 1000)
    return () => clearInterval(interval);
  }, [library, pendingTxns])

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateVaultTokenInfo(undefined, true)
        updateTokenBalances(undefined, true)
        updatePositionData(undefined, true)
        updateFundingRateInfo(undefined, true)
        updateMaxUsdg(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateVaultTokenInfo,
      updateTokenBalances, updatePositionData,
      updateFundingRateInfo, updateMaxUsdg])

  const [orders, updateOrders] = useOrders(active, library, account);
  useEffect(() => {
    function onBlock() {
      updateOrders(undefined, true)
    }
    if (active) {
      library.on('block', onBlock)
      return () => {
        library.removeListener('block', onBlock)
      }
    }
  }, [active, library, updateOrders])

  const infoTokens = getInfoTokens(tokens, tokenBalances, whitelistedTokens, vaultTokenInfo, fundingRateInfo)
  const { positions, positionsMap } = getPositions(positionQuery, positionData, infoTokens, savedIsPnlInLeverage)

  const [flagOrdersEnabled] = useLocalStorageSerializeKey([CHAIN_ID, "Flag-orders-enabled"], CHAIN_ID === TESTNET);
  const LIST_SECTIONS = [
    'Positions',
    flagOrdersEnabled ? 'Orders' : undefined,
    'Trades'
  ].filter(Boolean)
  const LIST_SECTIONS_LABELS = {
    'Positions': positions.length ? `Positions (${positions.length})` : undefined,
    'Orders': orders.length ? `Orders (${orders.length})` : undefined
  }
  let [listSection, setListSection] = useLocalStorageSerializeKey([CHAIN_ID, 'List-section'], LIST_SECTIONS[0]);
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0]
  }

  const getListSection = () => {
    return (
      <div>
        <Tab
          options={LIST_SECTIONS}
          optionLabels={LIST_SECTIONS_LABELS}
          option={listSection}
          onChange={section => setListSection(section)}
          type="inline"
          className="Exchange-list-tabs"
        />
        {listSection === 'Positions' &&
          <PositionsList
            positions={positions}
            positionsMap={positionsMap}
            infoTokens={infoTokens}
            active={active}
            account={account}
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            flagOrdersEnabled={flagOrdersEnabled}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            orders={orders}
          />
        }
        {listSection === 'Orders' &&
          <OrdersList
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            infoTokens={infoTokens}
            positionsMap={positionsMap}
            orders={orders}
            updateOrders={updateOrders}
          />
        }
        {listSection === 'Trades' &&
          <TradeHistory
            account={account}
            infoTokens={infoTokens}
            getTokenInfo={getTokenInfo}
          />
        }
      </div>
    )
  }

  const onSelectWalletToken = (token) => {
    setFromTokenAddress(token.address)
  }

  const renderChart = () => {
    if (chartType === CHART_TV) {
      return <ExchangeTVChart
        fromTokenAddress={fromTokenAddress}
        toTokenAddress={toTokenAddress}
        infoTokens={infoTokens}
        swapOption={swapOption}
      />
    }
    return <ExchangeChart
      fromTokenAddress={fromTokenAddress}
      toTokenAddress={toTokenAddress}
      infoTokens={infoTokens}
      swapOption={swapOption}
    />
  }

  return (
    <div className="Exchange">
      <div className="Exchange-content">
        <div className="Exchange-left">
          {renderChart()}
          <div className="Exchange-lists large">
            {getListSection()}
          </div>
        </div>
        <div className="Exchange-right">
          <SwapBox
            orders={orders}
            flagOrdersEnabled={flagOrdersEnabled}
            chainId={chainId}
            infoTokens={infoTokens}
            active={active}
            connectWallet={connectWallet}
            library={library}
            account={account}
            positionsMap={positionsMap}
            fromTokenAddress={fromTokenAddress}
            setFromTokenAddress={setFromTokenAddress}
            toTokenAddress={toTokenAddress}
            setToTokenAddress={setToTokenAddress}
            swapOption={swapOption}
            setSwapOption={setSwapOption}
            maxUsdg={reducedMaxUsdg}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            tokenSelection={tokenSelection}
            setTokenSelection={setTokenSelection}
            isConfirming={isConfirming}
            setIsConfirming={setIsConfirming}
            isPendingConfirmation={isPendingConfirmation}
            setIsPendingConfirmation={setIsPendingConfirmation}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
          />
          <div className="Exchange-wallet-tokens">
            <div className="Exchange-wallet-tokens-content border">
              <ExchangeWalletTokens
                tokens={tokens}
                mintingCap={maxUsdg}
                infoTokens={infoTokens}
                onSelectToken={onSelectWalletToken}
              />
            </div>
          </div>
        </div>
        <div className="Exchange-lists small">
          {getListSection()}
        </div>
      </div>
      <Footer />
    </div>
  )
}
