const SECONDS_PER_WEEK = 604800

const FEES = [
  {
    from: 1620205889 - SECONDS_PER_WEEK,
    to: 1620205889,
    feeAssets: "0.1603 BTC, 4.4701 ETH, 42.7548 BNB, 13,709.5913 BUSD, 6,134.1901 USDC, 8,134.1596 USDT",
    feeUsd: "79826.79"
  },
  {
    from: 1620786634 - SECONDS_PER_WEEK,
    to: 1620786634,
    feeAssets: "0.0412 BTC, 5.6775 ETH, 39.8377 BNB, 10,228.1919 BUSD, 11,095.6480 USDC, 1,163.4386 USDT",
    feeUsd: "75618.63"
  },
  {
    from: 1621393956 - SECONDS_PER_WEEK,
    to: 1621393956,
    feeAssets: "0.1596 BTC, 8.2261 ETH, 225.1073 BNB, 9,472.8827 BUSD, 66,029.0305 USDC, 15,171.2389 USDT",
    feeUsd: "226885.65"
  },
  {
    from: 1621999187 - SECONDS_PER_WEEK,
    to: 1621999187,
    feeAssets: "0.2624 BTC, 8.7960 ETH, 56.3157 BNB, 25,812.4605 BUSD, 19,202.5132 USDC, 3,233.8975 USDT",
    feeUsd: "103498.60"
  },
  {
    from: 1622594843 - SECONDS_PER_WEEK,
    to: 1622594843,
    feeAssets: "0.0434 BTC, 1.5276 ETH, 29.6398 BNB, 44,034.42733 BUSD, 957.2660 USDC, 6,259.4550 USDT",
    feeUsd: "67466.93"
  },
  {
    from: 1623204440 - SECONDS_PER_WEEK,
    to: 1623204440,
    feeAssets: "0.1210 BTC, 4.7566 ETH, 109.0878 BNB, 79,148.7061 BUSD, 404.5143 USDC, 26,713.8593 USDT",
    feeUsd: "158720.51"
  },
  {
    from: 1623806161 - SECONDS_PER_WEEK,
    to: 1623806161,
    feeAssets: "0.0963 BTC, 1.4782 ETH, 15.1441 BNB, 9,259.8002 BUSD, 444.7038 USDC, 5,332.7327 USDT",
    feeUsd: "27984.96"
  },
  {
    from: 1624416975 - SECONDS_PER_WEEK,
    to: 1624416975,
    feeAssets: "0.1862 BTC, 4.5722 ETH, 38.4881 BNB, 13,157.9102 BUSD, 681.9518 USDC, 20,719.0259 USDT",
    feeUsd: "61211.44"
  }
]

export function getFeeHistory() {
  return FEES.concat([]).reverse()
}
